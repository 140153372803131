import {
  FormattedHTMLMessage,
  FormattedMessage,
  injectIntl
} from "gatsby-plugin-intl";
import React from "react";
import Helmet from "react-helmet";
import Container from "../components/Container";
import FooterBackground from "../components/FooterBackground";
import {
  appstore, circleSmall,
  elediogo, googleplay,
  levelmatix,
  poolmatix,
  poweredByEledioGo,
  poweredByLiftmatix,
  poweredByPoolmatix,
  qrcode,
  target, waveHorizontalLarge,
  waveHorizontalSmall,
  waveVerticalLarge,
  waveVerticalSmall
} from "../components/Images";
import Layout from "../components/Layout";
import Row from "../components/Row";
import CTA from "../partials/CTA";
import styled from "../styling/styled";
import { theme } from "../styling/theme";

const Content = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;
  ${[theme.media.maxM]} {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  img.logo {
    height: 30px;
    margin-top: 8px;
    display: block;
  }
`;

const ContentHeader = styled(Content)`
  position: relative;
  padding-top: 120px;
  padding-bottom: 60px;
  background-size: 160px 17px, 160px 17px, 28px 28px;
  background-image: url(${waveHorizontalLarge}), url(${waveHorizontalLarge}),
    url(${target});
  background-repeat: no-repeat;
  background-position: left calc(${theme.container.background} - 277px) top 90px,
    right calc(${theme.container.background} - 220px) bottom,
    right calc(${theme.container.background} - 50px) top 15%;

  ${[theme.media.mobileMenuVisible]} {
    padding-top: 75px;
    padding-bottom: 15px;
  }
`;

const Col6 = styled.div`
  max-width: 50%;
  flex-basis: 50%;
  padding-left: 30px;
  padding-right: 30px;
  ${[theme.media.maxM]} {
    max-width: 100%;
    flex-basis: 100%;
  }
`;

const Illustration = styled.img`
  display: block;
  max-width: 100%;
  width: 100%;
  margin-top: -15%;
  pointer-events: none;
  ${[theme.media.maxM]} {
    position: absolute;
    top: 60px;
    right: 0px;
    width: 250px;
    z-index: -1;
    margin: 0;
  }
  ${[theme.media.maxS]} {
    position: static;
    margin-top: 0;
    top: 60px;
    right: -40px;
    width: 100%;
  }
`;

const Subheading = styled.h3`
  margin-bottom: 0.5em;
`;

const StoreLinks = styled.div`
  display: flex;
  align-items: center;

  img {
    display: block;
    margin-right: 11px;
  }
`;

const ContentDescription = styled(Content)`
  padding-top: 100px;
  padding-bottom: 100px;
  background-repeat: no-repeat;
  background-size: 17px 17px, 47px 17px;
  background-image: url(${circleSmall}), url(${waveHorizontalSmall});
  background-position: left calc(${theme.container.background} - 147px) bottom
      30%,
    right calc(${theme.container.background} - 100px) bottom 100px;
`;

const ContentBlue = styled(Content)`
  position: relative;
  background-repeat: no-repeat;
  background-size: 17px 47px, 17px 160px;
  background-image: url(${waveVerticalSmall}), url(${waveVerticalLarge}),
    url(${target});
  background-position: left calc(${theme.container.background} - 177px) bottom
      40%,
    right calc(${theme.container.background} - 130px) bottom 50%;
  &::after {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(
      to bottom,
      #f0f4fe 0%,
      rgba(240, 244, 254, 0) 100%
    );
  }
`;

const ColScreen = styled.div`
  max-width: calc(100% / 3);
  flex: 0 0 calc(100% / 3);
  margin-top: -250px;
  ${[theme.media.maxM]} {
    max-width: 100%;
    flex-basis: 100%;
    margin-top: 0;
    img {
      margin-top: 30px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const RowStores = styled(Row)`
  align-items: center;
  justify-content: space-between;
  ${[theme.media.maxL]} {
    display: block;
  }

  h4 {
    font-family: ${theme.font.montserrat};
    font-size: 22px;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    font-weight: 400;
    color: ${theme.colors.gray};
    line-height: 1;
    margin-right: 30px;
  }
`;

const QRCode = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${[theme.media.maxL]} {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  h4 {
    flex: 0;
    margin-right: 0;
  }

  img {
    margin-top: 4%;
  }
`;

const Col6StoreLinks = styled(Col6)`
  ${[theme.media.maxL]} {
    max-width: 100%;
    flex: 0 0 100%;
  }
`;

const QRCodeImage = styled.img``;

class BluePage extends React.Component {
  render() {
    const intl = this.props.intl;
    return (
      <>
        <Helmet
          title="Powered by Eledio"
          meta={[
            {
              name: "description",
              content: "Put your swimming pool control on your mobile phone."
            },
            {
              property: "og:title",
              content: "Powered by Eledio"
            },
            {
              property: "og:description",
              content: "Put your swimming pool control on your mobile phone."
            }
          ]}
        >
          <html lang="en" />
        </Helmet>
        <Layout>
          <ContentHeader>
            <Container>
              <Row spacing={30}>
                <Col6>
                  <h1>
                    <img src={poolmatix} alt="Poolmatix" className="logo" />
                  </h1>
                  <Subheading>
                    <FormattedMessage id="pages.poweredby.poolmatix.claim" />
                  </Subheading>
                  <p>
                    <FormattedHTMLMessage id="pages.poweredby.poolmatix.text" />
                  </p>
                </Col6>
                <Col6>
                  <Illustration
                    src={poweredByPoolmatix}
                  />
                </Col6>
              </Row>
            </Container>
          </ContentHeader>
          <ContentHeader>
            <Container>
              <Row spacing={30}>
                <Col6>
                  <h1>
                    <img src={levelmatix} alt="Levelmatix" className="logo" />
                  </h1>
                  <Subheading>
                    <FormattedMessage id="pages.poweredby.levelmatix.claim" />
                  </Subheading>
                  <p>
                    <FormattedHTMLMessage id="pages.poweredby.levelmatix.text" />
                  </p>
                </Col6>
                <Col6>
                  <Illustration
                    src={poweredByLiftmatix}
                  />
                </Col6>
              </Row>
            </Container>
          </ContentHeader>
          <ContentHeader>
            <Container>
              <Row spacing={30}>
                <Col6>
                  <h1>
                    <img src={elediogo} alt="Eledio Go" className="logo" />
                  </h1>
                  <Subheading>
                    <FormattedMessage id="pages.poweredby.elediogo.claim" />
                  </Subheading>
                  <p>
                    <FormattedHTMLMessage id="pages.poweredby.elediogo.text" />
                  </p>
                </Col6>
                <Col6>
                  <Illustration
                    src={poweredByEledioGo}
                  />
                </Col6>
              </Row>
            </Container>
          </ContentHeader>
          <ContentDescription id="ix-manager">
            <Container>
              <Subheading>
                <FormattedMessage id="pages.poweredby.mobile.title1" />
              </Subheading>
              <p>
                <FormattedHTMLMessage id="pages.poweredby.mobile.text1" />
              </p>
            </Container>
          </ContentDescription>
          <ContentBlue>
            <Container>
              {/* <Row spacing={30}>
                <ColScreen>
                  <img src={app1} srcSet={`${app1}, ${app1_2x} 2x`} />
                  <ScreenDescription>
                    <FormattedHTMLMessage id="pages.poweredby.mobile.screen1" />
                  </ScreenDescription>
                </ColScreen>
                <ColScreenMiddle>
                  <img src={app1} srcSet={`${app2}, ${app2_2x} 2x`} />
                  <ScreenDescription>
                    <FormattedHTMLMessage id="pages.poweredby.mobile.screen2" />
                  </ScreenDescription>
                </ColScreenMiddle>
                <ColScreen>
                  <img src={app1} srcSet={`${app3}, ${app3_2x} 2x`} />
                  <ScreenDescription>
                    <FormattedHTMLMessage id="pages.poweredby.mobile.screen3" />
                  </ScreenDescription>
                </ColScreen>
              </Row> */}
              <RowStores spacing={15}>
                <Col6StoreLinks>
                  <StoreLinks>
                    <h4>
                      <FormattedMessage id="pages.poweredby.mobile.title2" />
                    </h4>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={intl.formatMessage({ id: "pages.software.appstoreurl" })}
                    >
                      <img src={appstore} />
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={intl.formatMessage({ id: "pages.software.playurl" })}
                    >
                      <img src={googleplay} />
                    </a>
                  </StoreLinks>
                </Col6StoreLinks>
                <Col6StoreLinks>
                  <QRCode>
                    <h4>
                      <FormattedHTMLMessage id="pages.poweredby.mobile.title3" />
                    </h4>
                    <QRCodeImage src={qrcode} />
                  </QRCode>
                </Col6StoreLinks>
              </RowStores>
            </Container>
          </ContentBlue>
          <CTA
            buttonLink="/contact/"
            buttonText={intl.formatMessage({ id: "pages.software.ctatext" })}
            claim={intl.formatMessage({ id: "pages.software.ctaclaim" })}
          />
          <FooterBackground />
        </Layout>
      </>
    );
  }
}

export default injectIntl(BluePage);
